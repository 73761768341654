#admin #header {
  border-bottom: 2px solid #999;
}

#header {
  display: flex;
  justify-content: space-between;
  padding: 20px 50px;
}

#header #page-title {
  font-size: 30px;
  padding: 20px;
  transition: all .2s;
  text-decoration: none;
  display: block;
  color: #333;
  border-radius: 5px;
}

#header #page-title:hover {
  color: #006cb4;
}

#header #navigation {
  position: relative;
  top: 10px;
}

#header #navigation a {
  padding: 20px;
  padding-bottom: 10px;
  margin: 10px;
  text-decoration: none;
  font-size: 18px;
  color: #333;
  transition: all 0.2s;
  border-radius: 3px;
  position: relative;
}

#header #navigation a:hover {
  background-color: rgba(3, 128, 211, 0.1);
  color: #006cb4;
  border-bottom: 10px solid rgba(0, 93, 180, 0.5);
}

#header #navigation a .svgIcon {
  margin-left: 15px;
  margin-bottom: 5px;
}

#header #navigation a .cart-items-number {
  background-color: rgb(0, 183, 255);
  border-radius: 500px;
  text-align: center;
  width: 20px;
  color: #eee;
  font-size: 15px;
  padding: 3px;
  position: absolute;
  font-family: Arial, Arial, Helvetica, sans-serif;
  top: 3px;
  right: 7px;
  box-shadow: 2px 2px 5px #888;
}

@media only screen and (max-width: 1518px) {
  #header {
    margin: 0 40px;
  }
}

@media only screen and (max-width: 1145px) {
  #header {
    flex-direction: column;
    text-align: center;
  }
}

@media only screen and (max-width: 850px) {
  #header {
    border-radius: 0;
    border: none;
    border-bottom: 2px solid #999;
    padding: 20px 0;
  }

  #header #page-title {
    padding: 10px 0 0;
  }

  #header #navigation {
    padding: 0;
    display: flex;
    flex-direction: column;
  }

  #header #navigation a {
    margin: 0 auto;
    width: 200px;
    display: block;
    padding: 10px;
  }

  #header #navigation a:hover {
    border: none;
  }

  #header #navigation a .cart-items-number {
    top: 0;
    right: 58px;
  }
}
