.gender-label {
  display: inline-block;
  padding: 5px 15px;
  border-radius: 5px;
  color: #f5f5f5;
  opacity: .9;
}

.gender-label.male {
  background-color: rgb(0, 132, 255);
}

.gender-label.female {
  background-color: rgb(216, 44, 44);
}