#user {
  width: 70%;
  margin: auto;
  background-color: #eee;
}

@media only screen and (max-width: 1518px) {
  #user {
    width: 100%;
    background-color: #f5f5f5;
  }
}
