.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .4);
  z-index: 500;
}

.overlay-container {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f5f5f5;
  border-radius: 5px;
  max-width: 80%;
  min-width: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 98vh;
}

.close-overlay {
  position: absolute;
  top: 10px;
  right: 12px;
}

.close-overlay:hover {
  cursor: pointer;
}

.overlay-content {
  padding: 20px;
  height: 100%;
  width: 90%;
  margin: auto;
}

.overlay-title {
  text-align: center;
  font-weight: normal;
}

.overlay-cta {
  background-color: transparent;
  border-radius: 10px;
  padding: 10px 15px;
  margin: 15px auto;
  display: block;
  cursor: pointer;
}

@media only screen and (max-width: 700px) {
  .overlay-container {
    min-width: 330px;
  }
}

@media only screen and (max-width: 500px) {
  .overlay-container {
    max-width: 95% !important;
  }
}