.full-robe-card {
  display: flex;
  position: relative;
  justify-content: space-between;
  margin: 20px;
  border-radius: 3px;
  width: 1000px;
  margin: auto;
  text-decoration: none;
  color: #444;
  font-size: 17px;
  transition: all 0.2s;
}

.full-robe-card .left-side {
  margin-right: 20px;
}

.full-robe-card .right-side {
  width: 90%;
  padding: 0 20px;
}

.full-robe-card img {
  display: block;
  border-radius: 5px;
  max-height: 400px;
  min-height: 400px;
  min-width: 400px;
  width: auto;
  height: auto;
}

.full-robe-card .robe-title {
  margin-bottom: 20px;
}

.full-robe-card .robe-colors {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  margin: 15px 0;
}

.full-robe-card .check-product-btn {
  color: #222;
  display: block;
  background-color: #ddd;
  font-size: 18px;
  border: none;
  padding: 10px 15px;
  margin: 35px auto 15px;
  cursor: pointer;
  transition: all 0.2s;
}

.full-robe-card .check-product-btn:hover {
  background-color: #ccc;
}

@media only screen and (max-width: 1200px) {
  .full-robe-card {
    width: 90%;
  }

  .full-robe-card img {
    max-height: 300px;
    min-height: 300px;
    min-width: 300px;
  }

  .full-robe-card .robe-title {
    font-size: 20px;
  }
}

@media only screen and (max-width: 800px) {
  .full-robe-card {
    width: 400px;
    flex-direction: column;
    text-align: center;
  }

  .full-robe-card .robe-title {
    margin-bottom: 10px;
    margin-top: 15px;
  }

  .full-robe-card .robe-colors {
    justify-content: center;
  }

  .full-robe-card .robe-price {
    margin-top: 25px;
  }

  .full-robe-card img {
    display: block;
    margin: auto;
    max-width: 200px;
    max-height: 200px;
    width: 200px;
  }

  .full-robe-card .robe-description {
    display: none;
  }

  .full-robe-card .check-product-btn {
    color: #222;
    display: block;
    background-color: #ddd;
    font-size: 18px;
    border: none;
    padding: 10px 15px;
    margin: 15px auto;
    cursor: pointer;
    transition: all 0.2s;
  }
}

@media only screen and (max-width: 420px) {
  .full-robe-card {
    width: 85vw;
  }

  .full-robe-card img {
    max-width: 85vw;
  }
}
