#cart {
  padding-bottom: 50px;
}

#cart #cart-title {
  margin-top: 60px;
  margin-bottom: 30px;
  text-align: center;
  font-size: 32px;
}

#cart #cart-items {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

#cart #remove-button {
  color: rgb(59, 0, 0);
  border: 2px solid rgb(139, 0, 0);
  background-color: rgb(255, 160, 160);
}

#cart .checkout-btn {
  display: block;
  margin: 40px auto;
  color: #222;
  background-color: #ddd;
  font-size: 18px;
  border: none;
  padding: 10px 15px;
  margin-bottom: 20px;
  margin: auto;
  cursor: pointer;
  transition: all 0.2s;
}

#cart .checkout-btn:hover {
  background-color: #ccc;
}