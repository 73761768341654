#detailed-product {
  padding-top: 60px;
}

#detailed-product .product-title {
  text-align: center;
  font-size: 30px;
}

#detailed-product .flex-container {
  display: flex;
  justify-content: center;
  width: 80%;
  margin: auto;
  margin-top: 50px;
  color: #333;
  transition: all 0.2s;
}

#detailed-product .product-info {
  margin: auto;
  padding-bottom: 70px;
  width: 50%;
  font-size: 20px;
}

#detailed-product .card-slider {
  width: 470px;
  margin-bottom: 50px;
}

#detailed-product .left-side {
  text-align: center;
}

#detailed-product .right-side {
  text-align: center;
  margin-left: 70px;
}

#detailed-product .slider-image,
#detailed-product .slider-pattern {
  border: 1px solid #6e6e6e;
  max-height: 430px;
  min-height: 430px;
  max-width: 430px;
  border-radius: 15px;
  width: auto;
  height: auto;
}

#detailed-product .card-slider .slick-arrow::before {
  opacity: 1;
  color: #333;
}

#detailed-product .card-slider .slick-arrow {
  border-radius: 50%;
}

#detailed-product .product-colors {
  display: flex;
  justify-content: center;
  margin: 20px auto;
}

#detailed-product .gender-label {
  margin-bottom: 15px;
}

#detailed-product .product-title {
  margin-bottom: 30px;
}

#detailed-product .robe-sizes-reference {
  max-width: 400px;
}

#detailed-product .add-to-cart {
  color: #222;
  background-color: #ddd;
  display: block;
  font-size: 18px;
  border: none;
  padding: 10px 15px;
  margin: auto;
  margin-bottom: 15px;
  cursor: pointer;
  transition: all 0.2s;
}

#detailed-product .add-to-cart:hover {
  background-color: #ccc;
}

@media only screen and (max-width: 1150px) {
  #detailed-product .flex-container {
    width: 75vw;
    flex-direction: column;
    margin: auto;
  }

  #detailed-product .flex-container > * {
    margin: auto;
  }

  #detailed-product .right-side {
    margin-top: 35px;
  }

  #detailed-product .product-title {
    font-size: 23px;
  }

  #detailed-product .gender-label {
    margin-bottom: 0;
  }

  #detailed-product .slider-image,
  #detailed-product .slider-pattern {
    margin: auto;
  }
}

@media only screen and (max-width: 580px) {
  #detailed-product .flex-container {
    width: 100%;
  }

  #detailed-product .card-slider {
    width: 330px;
  }

  #detailed-product .slider-image,
  #detailed-product .slider-pattern {
    max-width: 300px;
    max-height: 300px;
    min-height: 300px;
  }

  #detailed-product .product-title {
    font-size: 20px;
  }

  #detailed-product .product-info {
    width: 80%;
  }

  #detailed-product .robe-sizes-reference {
    max-width: 85%;
  }
}
