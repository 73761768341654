body {
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
  color: #333;
  font-family: 'Archivo', sans-serif;
}

* {
  outline: none;
}
