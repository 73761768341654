#contact {
  text-align: center;
}

#contact h1 {
  padding-top: 100px;
  font-weight: normal;
  font-size: 40px;
  text-decoration: underline;
}

#contact #contact-info {
  margin-top: 150px;
  font-size: 30px;
  letter-spacing: 1px;
}

@media only screen and (max-width: 500px) {
  #contact h1 {
    font-size: 30px;
  }

  #contact #contact-info {
    font-size: 20px;
  }
}
