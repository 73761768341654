#add-to-cart-overlay > * {
  text-align: center;
}

#add-to-cart-overlay .overlay-title {
  margin-top: 15px;
  border-bottom: 2px solid #999;
  padding-bottom: 15px;
}

#add-to-cart-overlay .add-robe-title {
  font-size: 23px;
  margin-bottom: 30px;
  text-align: center;
  font-weight: normal;
}

#add-to-cart-overlay .robe-colors {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 15px auto;
}

#add-to-cart-overlay .robe-colors > * {
  cursor: pointer;
  margin-bottom: 10px;
}

#add-to-cart-overlay .input-label {
  font-size: 20px;
  font-weight: normal;
  margin: 10px;
}

#add-to-cart-overlay select {
  width: 100px;
  margin-bottom: 20px;
  color: #333;
  font-size: 20px;
  background: none;
  border: 2px solid #666;
  border-radius: 5px;
}

#add-to-cart-overlay #quantity-input {
  width: 85px;
  padding: 1px 5px;
  color: #333;
  font-size: 20px;
  background: none;
  border: 2px solid #666;
  border-radius: 5px;
}

#add-to-cart-overlay button[type='submit'] {
  background-color: rgb(0, 121, 202);
  opacity: 0.9;
  font-size: 17px;
  text-decoration: none;
  padding: 15px 20px;
  border-radius: 10px;
  text-align: center;
  margin: 40px auto 20px;
  font-weight: bold;
  color: rgb(255, 255, 255);
  border: none;
  display: block;
  transition: all 0.2s;
}

#add-to-cart-overlay button[type='submit']:hover {
  cursor: pointer;
  padding: 15px 25px;
  opacity: 1;
}
