#new-product {
  margin: auto;
  padding: 50px;
}

#new-product h2 {
  text-align: center;
  font-size: 32px;
}

@media only screen and (max-width: 600px) {
  #new-product {
    padding: 0;
  }
}
