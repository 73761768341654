#checkout .checkout-title {
  text-align: center;
  margin: 60px auto 30px;
}

#checkout > .flex-container {
  display: flex;
  justify-content: space-around;
}

#checkout > .flex-container > * {
  width: 40%;
  padding: 20px;
}

#checkout > .flex-container .title {
  text-align: center;
  font-weight: normal;
}

#checkout .compact-products-list {
  max-height: 350px;
  overflow: auto;
}

#checkout .place-order-btn {
  background-color: rgb(0, 121, 202);
  margin: 20px auto 50px;
  opacity: 0.9;
  font-size: 17px;
  text-decoration: none;
  padding: 15px 20px;
  border-radius: 10px;
  text-align: center;
  font-weight: bold;
  color: rgb(255, 255, 255);
  border: none;
  display: block;
  transition: all .2s;
}

#checkout .place-order-btn:hover {
  cursor: pointer;
  padding: 15px 25px;
  opacity: 1;
}

#checkout .total-price {
  text-align: center;
  font-size: 18px;
}

#checkout .total-price span {
  font-weight: bold;
}

@media only screen and (max-width: 1800px) {
  #checkout > .flex-container {
    flex-direction: column;
  }

  #checkout > .flex-container > * {
    margin: 10px auto;
    width: 70%;
  }
}

@media only screen and (max-width: 780px) {
  #checkout > .flex-container > * {
    margin: 5px auto;
    padding-top: 0;
    width: 90%;
  }

  #checkout .compact-products-list {
    width: 100%;
    overflow-y: auto;
  }
}