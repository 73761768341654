#about {
  padding: 0 100px;
}

#about .title {
  text-align: center;
  margin-bottom: 50px;
  font-size: 40px;
}

#about p {
  font-size: 22px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1145px) {
  #about {
    padding: 0 50px;
  }
}

@media only screen and (max-width: 650px) {
  #about {
    padding: 0 20px;
  }

  #about p {
    font-size: 18px;
    margin-bottom: 20px;
  }
}
