#filters {
  display: flex;
  justify-content: center;
  margin: 30px auto 0 auto;
  max-width: 95vw;
}

#filters .gender {
  border: 3px solid #555;
  padding: 10px;
  cursor: pointer;
  text-transform: uppercase;
  width: 90px;
  text-align: center;
  transition: all 0.2s;
}

#filters .gender:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: none;
  height: 35px;
  line-height: 35px;
}

#filters .gender:nth-child(2) {
  width: 110px;
}

#filters .gender:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: none;
  height: 35px;
  line-height: 35px;
}
