#product-form.form-container {
  width: 40%;
  margin: auto;
}

#product-form form {
  width: 70%;
  background-color: #eee;
  padding: 30px 100px 70px 50px;
  padding-right: 100px;
  padding-bottom: 70px;
  margin: 40px auto;
  border-radius: 5px;
}

#product-form form input,
#product-form form textarea {
  width: 100%;
  font-size: 17px;
  padding: 10px 25px;
  background-color: #f5f5f5;
  border: 2px solid #999;
  color: #333;
  border-radius: 5px;
  margin: 10px auto 25px;
}

#product-form form input[name='price'] {
  width: 80px;
  margin-right: 50px;
}

#product-form form textarea {
  height: 120px;
  max-height: 250px;
  min-height: 50px;
  max-width: 100%;
  min-width: 100%;
}

#product-form form .pattern-inputs .pattern-inputs-bottons,
#product-form form .image-inputs .image-inputs-bottons {
  display: flex;
  margin-left: -4px;
  margin-top: 10px;
}

#product-form form .pattern-inputs .pattern-inputs-bottons input[type='button'],
#product-form form .image-inputs .image-inputs-bottons input[type='button'] {
  font-size: 18px;
  width: 33px;
  height: 33px;
  text-align: center;
  display: block;
  margin: 4px 5px 0 5px;
  padding: 0;
  background-color: transparent;
  border: 2px solid #999;
  border-radius: 5px;
  color: #333;
}

#product-form form .pattern-field,
#product-form form .image-field {
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
}

#product-form form .pattern-input,
#product-form form .image-input {
  margin-bottom: 0;
  height: 20px;
  margin-right: 10px;
  margin-top: 0;
  font-size: 13px;
}

#product-form form .pattern-number,
#product-form form .image-number {
  width: 20px;
  border: 2px solid #999;
  border-radius: 5px;
  background-color: #ddd;
  font-size: 18px;
  text-align: center;
  padding: 10px;
  height: 20px;
}

#product-form form .color-pickers {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
}

#product-form form .color-pickers input[type='color'] {
  height: 30px;
  width: 30px;
  padding: 0;
  margin: 5px 5px;
  border: none;
  background-color: transparent;
}

#product-form form .color-pickers input[type='button'] {
  font-size: 18px;
  width: 33px;
  height: 33px;
  text-align: center;
  display: block;
  margin: 4px 5px 0 5px;
  padding: 0;
  background-color: transparent;
  border: 2px solid #999;
  border-radius: 5px;
  color: #333;
}

#product-form #images-input {
  font-size: 12px;
}

#product-form form button[type='submit'] {
  background-color: rgb(0, 121, 202);
  position: relative;
  top: 35px;
  left: 30px;
  opacity: 0.9;
  font-size: 17px;
  text-decoration: none;
  padding: 15px 20px;
  border-radius: 10px;
  text-align: center;
  margin: auto;
  font-weight: bold;
  color: rgb(255, 255, 255);
  border: none;
  display: block;
  transition: all 0.2s;
}

#product-form form button[type='submit']:hover {
  cursor: pointer;
  padding: 15px 25px;
  opacity: 1;
}

@media only screen and (max-width: 1500px) {
  #product-form.form-container {
    width: 70%;
  }
}

@media only screen and (max-width: 1000px) {
  #product-form.form-container {
    width: 90%;
  }
}

@media only screen and (max-width: 600px) {
  #product-form form {
    margin-left: -20px;
    width: 85%;
    padding-left: 20px;
    padding-right: 70px;
  }
}
