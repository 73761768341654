#robes h2 {
  margin-top: 60px;
  text-align: center;
  font-size: 32px;
}

#robes #robe-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

#robes #remove-button {
  color: rgb(59, 0, 0);
  border: 2px solid rgb(139, 0, 0);
  background-color: rgb(255, 160, 160);
}
