.compact-product {
  display: flex;
  border: 2px solid #555;
  margin: auto;
  margin-bottom: 15px;
  border-radius: 5px;
  width: 530px;
  max-height: 100px;
}

.compact-product * {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.compact-product img {
  max-width: 100px;
  max-height: 100px;
  min-width: 100px;
  min-height: 100px;
  overflow: hidden;
}

.compact-product > *:not(:first-child) {
  font-weight: normal;
  margin: 10px 0;
  line-height: 80px;
  font-size: 18px;
}

.compact-product .robe-title {
  flex-basis: 150px;
  margin: 10px;
}

.compact-product .robe-pattern {
  flex-basis: 50px;
  text-align: center;
  border-left: 2px solid #ccc;
}

.compact-product .empty-field {
  flex-basis: 52px;
  text-align: center;
}

.compact-product .robe-size {
  flex-basis: 60px;
  text-align: center;
  border-left: 2px solid #ccc;
}

.compact-product .robe-color {
  padding-top: 30px;
  padding-left: 10px;
  padding-right: 10px;
  border-left: 2px solid #ccc;
}

.compact-product .robe-quantity {
  padding-left: 30px;
  border-left: 2px solid #ccc;
}

@media only screen and (max-width: 780px) {
  .compact-product {
    width: 470px;
    max-height: 60px;
  }

  .compact-product img {
    max-width: 60px;
    max-height: 60px;
    min-width: 60px;
    min-height: 60px;
  }

  .compact-product > *:not(:first-child) {
    line-height: 40px;
    font-size: 16px;
  }

  .compact-product .robe-color {
    padding-top: 13px;
  }
}

@media only screen and (max-width: 540px) {
  .compact-product {
    width: 380px;
  }

  .compact-product .robe-title {
    flex-basis: 115px;
    padding-left: 10px;
  }

  .compact-product .robe-size {
    flex-basis: 40px;
  }

  .compact-product .robe-color {
    padding-top: 10px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .compact-product .robe-quantity {
    padding-left: 20px;
    border-left: 2px solid #ccc;
  }
}