#login {
  margin: auto;
}

#login h2 {
  text-align: center;
  font-size: 32px;
}

#login form {
  width: 70%;
  margin: 40px auto;
  border-radius: 5px;
}

#login form input {
  width: 100%;
  font-size: 17px;
  padding: 10px 25px;
  background-color: #f5f5f5;
  border: 2px solid #999;
  color: #333;
  border-radius: 5px;
  margin: 10px auto;
}

#login .form-container {
  width: 50%;
  margin: auto;
}

#login form input:-moz-placeholder,
#login form input::-moz-placeholder {
  color: #111;
}

#login form #email:not(:placeholder-shown):invalid,
#login form #password:not(:placeholder-shown):invalid {
  border: 1px solid rgb(200, 0, 0);
}

#login form button[type="submit"] {
  background-color: rgb(0, 121, 202);
  position: relative;
  top: 15px;
  opacity: 0.9;
  font-size: 17px;
  text-decoration: none;
  padding: 15px 20px;
  border-radius: 10px;
  text-align: center;
  margin: auto;
  font-weight: bold;
  color: rgb(255, 255, 255);
  border: none;
  display: block;
  transition: all .2s;
}

#login form button[type="submit"]:hover {
  cursor: pointer;
  padding: 15px 25px;
  opacity: 1;
}

@media only screen and (max-width: 780px) {
  #login .form-container {
    width: 90%;
  }

  #login form {
    width: 80vw;
    padding: 0;
  }

  #login form input {
    width: 85%;
  }
}