.success-container {
  text-align: center;
  background-color: rgba(89, 206, 252, 0.9);
  top: 120px;
  position: fixed;
  border-radius: 5px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
}

.success {
  color: #00305c;
  padding: 20px 40px;
  font-size: 15px;
  margin: auto;
  display: inline-block;
}

.success-container button {
  background-color: transparent;
  transition: all .2s;
  position: absolute;
  right: 0px;
  top: 5px;
  opacity: .5;
  background: none;
  color: #333;
  border: none;
  transform: scale(.5);
  border-radius: 5px;
  cursor: pointer;
}

.success-container button:hover {
  opacity: .8;
}