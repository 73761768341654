.robe-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
  z-index: 10;
  margin: 20px;
  width: 350px;
  text-decoration: none;
  color: #444;
  text-align: center;
  border: 2px solid transparent;
  cursor: pointer;
  font-size: 17px;
  transition: all 0.4s;
}

.robe-card:hover img {
  transform: scale(1.1);
}

.robe-card .image-container {
  z-index: 10;
  max-width: 350px;
  min-width: 350px;
}

.robe-card img,
.robe-card .image-container {
  display: block;
  overflow: hidden;
  max-width: 350px;
  min-width: 350px;
  min-height: 100px;
  width: auto;
  height: auto;
  transition: all 0.3s ease-out;
}

.robe-card .robe-title {
  text-align: left;
  font-weight: normal;
  padding: 10px;
  margin: 0;
}

.robe-card .robe-colors {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 10px;
}

.robe-card .robe-colors > * {
  margin-bottom: 5px;
}

.robe-card .robe-price {
  font-size: 20px;
  margin: 10px 15px;
}

.robe-card .admin-controls {
  position: absolute;
  top: 5px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.robe-card .admin-controls .controls-left {
  left: 5px;
}

.robe-card .admin-controls .controls-right {
  right: 5px;
}

.robe-card .admin-controls > * > * {
  padding: 5px;
  opacity: 0.7;
  transition: all 0.2s;
  background-color: none;
  background: none;
  border: none;
  cursor: pointer;
}

.robe-card .admin-controls > * > *:hover {
  opacity: 1;
}

.robe-card .remove-from-cart-btn,
.robe-card .remove-from-featured-btn {
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 5px;
  opacity: 0.5;
  transition: all 0.2s;
  background-color: none;
  background: none;
  border: none;
  cursor: pointer;
}

.robe-card .remove-from-cart-btn:hover,
.robe-card .remove-from-featured-btn:hover {
  opacity: 1;
}

.robe-card .add-to-cart-btn {
  color: #222;
  background-color: #ddd;
  font-size: 18px;
  border: none;
  padding: 10px 15px;
  margin-bottom: 20px;
  cursor: pointer;
  transition: all 0.2s;
}

.robe-card .add-to-cart-btn:hover {
  background-color: #ccc;
}

.robe-card .order-details {
  margin-bottom: 15px;
}

.robe-card .order-details > * {
  font-family: Arial, Helvetica, sans-serif;
  margin-bottom: 8px;
}

.robe-card .bold {
  font-weight: bold;
  color: #444;
}

@media only screen and (max-width: 1200px) {
  .robe-card {
    width: 300px;
  }

  .robe-card .image-container,
  .robe-card img {
    max-width: 300px;
    min-width: 300px;
  }
}